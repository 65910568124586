<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockteaserIcon = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "content";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockteaserIcon;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot: any = getSlotContent("teaserIcon");

const { getConfigValue } = useCmsElementConfig(slot);
const contentWidth: "full" | "narrow" | "small" = getConfigValue("contentWidth");
const iconSize: "small" | "default" = getConfigValue("iconSize");
const align: "left" | "center" | "right" = getConfigValue("align");
const style: "horizontal" | "vertical" = getConfigValue("style");
const backgroundColor: "none" | "light" = getConfigValue("backgroundColor");
const teasers: any = getConfigValue("objItems");
</script>

<template>
  <div class="s-gutter" v-if="teasers.length >= 1">
    <div
      class="@container/teasericon"
      :class="{
        'c-width-default': contentWidth === 'full',
        'c-width-narrow': contentWidth === 'narrow',
        'c-width-small': contentWidth === 'small',
      }"
    >
      <div
        class="grid grid-cols-1"
        :class="{
          '@xl/teasericon:grid-cols-2': teasers.length > 2,
          '@5xl/teasericon:grid-cols-3': teasers.length > 3,
          '@7xl/teasericon:grid-cols-4': teasers.length > 4,
          '@9xl/teasericon:grid-cols-5': teasers.length > 5,
          'gap-16 xl:gap-24': backgroundColor === 'none',
          'gap-1': backgroundColor !== 'none',
        }"
      >
        <CmsElementTeaserIcon
          v-for="teaser in teasers"
          :key="teaser.sort_id"
          :title="teaser.headline"
          :text="teaser.text"
          :align="align"
          :style="style"
          :background-color="backgroundColor"
        >
          <SharedCmsImage
            v-if="teaser?.media"
            :src="teaser?.media?.url"
            :crop="teaser?.media?.crop"
            :focus-point="teaser?.media?.focusPoint"
            :alt="teaser.title"
            :height="iconSize === 'small' ? 45 : 90"
            class="aspect-square"
          />
        </CmsElementTeaserIcon>
      </div>
    </div>
  </div>
</template>
